



















































































































import { Component, Vue } from 'vue-property-decorator';

import authModule from '@/store/Auth';

import PageHeader from '@/components/common/PageHeader.vue';
import AvatarUpload from '@/components/common/AvatarUpload.vue';

import AccountCard from '@/components/dashboard/AccountCard.vue';
import SidebarInfo from '@/components/common/SidebarInfo.vue';
import SidebarTiles from '@/components/common/SidebarTiles.vue';

import LeaderboardStatsLines from '@/components/property/LeaderboardStatsLines.vue';
import FaunaLeaderboardStatsLines from '@/components/property/FaunaLeaderboardStatsLines.vue';

@Component({
  components: {
    PageHeader,
    AccountCard,
    AvatarUpload,
    LeaderboardStatsLines,
    FaunaLeaderboardStatsLines,
    SidebarInfo,
    SidebarTiles,
  },
})
export default class Dashboard extends Vue {
  get user() {
    return authModule.user;
  }

  get roles() {
    return this.user
      ? this.user.roles.map(role => role.substr(0, role.length - 1)).join(' / ')
      : '';
  }

  async changePrivacy() {
    authModule.setShowWelcomeDialog(true);
  }
}
