

































import { Component, Vue, Prop } from 'vue-property-decorator';
import CardInfo from '@/components/common/CardInfo.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';

import LazyImg from '@/components/common/LazyImg.vue';

import { FloraSurvey, SurveySite } from '@/api';

@Component({
  components: {
    LazyImg,
    UserAvatar,
    CardInfo,
  },
})
export default class SiteCard extends Vue {
  @Prop({ required: true }) readonly site: SurveySite;

  floraSurveys: FloraSurvey[] = [];

  async mounted() {
    // TODO: get flora survey
    this.floraSurveys = (
      await FloraSurvey.includes(['media'])
        .where({
          surveySite: this.site.id as string,
        })
        .all()
    ).data;
  }
}
