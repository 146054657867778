







import mapboxgl from 'mapbox-gl';

import { Component, Prop, Vue, Watch, VModel } from 'vue-property-decorator';
import { SurveyProperty, SurveySite } from '@/api';

import ItemBreadcrumb from '@/components/common/ItemBreadcrumb.vue';

import { createMapElement, getPropertyBounds, outlineProperty } from '@/util';

@Component({ components: { ItemBreadcrumb } })
export default class PropertyMap extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @VModel() selectedSite: string | null;

  map: mapboxgl.Map | null = null;

  markers: mapboxgl.Marker[] = [];

  get site() {
    return this.property.surveySites.find(
      site => site.id === this.selectedSite,
    );
  }

  async createMapElement() {
    return new Promise(resolve => {
      this.map = createMapElement(this.$refs.mapEl as HTMLElement);
      this.map.on('load', resolve);
      this.map.on('click', () => {
        this.selectedSite = null;
        this.setActiveMarker();
      });
    });
  }

  outlineProperty() {
    if (!this.property || !this.map) return;
    outlineProperty(this.map, this.property);
  }

  setSites() {
    if (!this.property || !this.map) return;

    this.selectedSite = null;
    while (this.markers.length) {
      const marker = this.markers.pop();
      if (marker) {
        marker.remove();
      }
    }

    const { map } = this;
    this.property.surveySites.forEach(site => {
      // Marker el
      const markerEl = document.createElement('div');
      markerEl.className = 'marker';
      markerEl.addEventListener('click', e => {
        e.stopPropagation();
        this.selectedSite = site.id as string;
        this.setActiveMarker(markerEl);
      });

      // Marker
      const marker = new mapboxgl.Marker(markerEl)
        .setLngLat(site.location.coordinates as mapboxgl.LngLatLike)
        .addTo(map);
      this.markers.push(marker);
    });
  }

  setBounds() {
    if (!this.property || !this.map) {
      return;
    }
    this.map.fitBounds(getPropertyBounds(this.property), { padding: 40 });
  }

  setActiveMarker(markerEl?: HTMLElement) {
    this.markers.forEach(marker => {
      const el = marker.getElement();
      if (el === markerEl) {
        if (this.map) {
          this.map.panTo(marker.getLngLat());
        }
        el.classList.add('marker--selected');
      } else {
        el.classList.remove('marker--selected');
      }
    });
  }

  async mounted() {
    await this.createMapElement();
    this.outlineProperty();
    this.setSites();
    this.setBounds();
  }

  beforeDestroy() {
    if (this.map) this.map.remove();
  }

  @Watch('property')
  propertyChanged() {
    this.outlineProperty();
    this.setSites();
    this.setBounds();
  }
}
