



































import { Component, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/common/PageHeader.vue';
import NotFound from '@/components/common/NotFound.vue';

import { numberFormat } from '@/util';

@Component({
  components: { PageHeader, NotFound },
})
export default class PropertySingleView extends Vue {
  get numberFormat() {
    return numberFormat;
  }
}
