























































































































import { Component, Vue } from 'vue-property-decorator';
import PageHeader from '@/components/common/PageHeader.vue';
import SidebarInfo from '@/components/common/SidebarInfo.vue';
import csvExportDialog from '@/csv-export-dialog';

@Component({ components: { PageHeader, SidebarInfo } })
export default class AdminDashboard extends Vue {
  showExportDialog() {
    csvExportDialog();
  }
}
