var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mb-4"},[_c('div',{staticClass:"stepper-header mt-16 mb-16"},[_c('div',[_c('h2',{staticClass:"mb-2"},[_vm._v("Survey #"+_vm._s(_vm.survey.id)+" published")])]),_c('div',[_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Error officia at unde iusto vero aliquid temporibus deserunt repellat expedita laboriosam numquam nam tempore eveniet dolorem harum, non quaerat blanditiis magnam! ")])]),_c('div',{staticClass:"survey-edit-step mt-8",class:{
      'survey-edit-step--active': _vm.survey.isProcessing,
      'survey-edit-step--complete': !_vm.survey.isProcessing,
    }},[_c('div',{staticClass:"survey-edit-step__inner"},[_c('div',{staticClass:"survey-edit-step__icon"},[(_vm.survey.isProcessing)?_c('v-icon',[_vm._v("mdi-creation")]):_c('v-icon',[_vm._v("mdi-check")])],1),(_vm.survey.isProcessing)?_c('div',{staticClass:"survey-edit-step__content"},[_c('h4',{staticClass:"survey-edit-step__title"},[_vm._v(" Stickybeak is processing your photos ")]),_c('div',{staticClass:"survey-edit-step__subtitle"},[_vm._v(" Stickybeak will process your photos, identifying which species it thinks are present, as well as marking photos that are blank. This may take some time, so please grab a cuppa while you wait. ")])]):_c('div',{staticClass:"survey-edit-step__content"},[_c('h4',{staticClass:"survey-edit-step__title"},[_vm._v(" Processing complete ")]),_c('div',{staticClass:"survey-edit-step__subtitle"},[_vm._v(" Thanks for your patience. Stickybeak has finished processing your photos and your survey is now ready for tagging. ")])]),_c('img',{staticClass:"stickybeak-progress",attrs:{"src":"/stickybeak.svg","alt":""}})]),(_vm.survey.isProcessing)?_c('div',{staticClass:"d-flex align-center gap-2 mt-8"},[_c('div',{staticClass:"flex-grow-1"},[_c('v-progress-linear',{attrs:{"rounded":"","stream":"","buffer-value":0,"value":(100 * _vm.survey.snsStatusPredicted) / _vm.survey.faunaMediaCount}})],1),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"primary"}},'v-icon',attrs,false),on),[_vm._v(" mdi-information-outline ")])]}}],null,false,238531693)},[_c('div',[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.snsStatusDetected + _vm.survey.snsStatusPredicted))+" / "+_vm._s(_vm.numberFormat(_vm.survey.faunaMediaCount))+" Detected ")]),_c('div',[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.snsStatusPredicted))+" / "+_vm._s(_vm.numberFormat(_vm.survey.faunaMediaCount))+" Predicted ")]),_c('div',[_vm._v("Last updated "+_vm._s(_vm.updatedTs))])])],1):_c('div',{staticClass:"stat-btn-grid mt-8"},[_c('stat-btn',{attrs:{"icon":"mdi-camera-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.totalCount))+" photos processed ")]),_c('stat-btn',{attrs:{"icon":"mdi-tag-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.totalBboxCount))+" animals found ")]),_c('stat-btn',{attrs:{"icon":"mdi-border-none-variant"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.blankMediaCount))+" blank photos ")])],1)]),_c('div',{staticClass:"survey-edit-step  mt-8",class:{
      'survey-edit-step--active': !_vm.survey.isProcessing,
      'survey-edit-step--disabled': _vm.survey.isProcessing,
      'survey-edit-step--complete': _vm.surveyInProgress,
    }},[_c('div',{staticClass:"survey-edit-step__inner"},[_c('div',{staticClass:"survey-edit-step__icon"},[_c('v-icon',[_vm._v("mdi-tag-outline")])],1),_vm._m(0),_c('div',{staticClass:"survey-edit-step__actions"},[_c('v-btn',{attrs:{"depressed":"","large":"","color":"primary","disabled":_vm.survey.isProcessing,"to":{
            name: 'classifier',
            params: { propertyId: _vm.property.id, faunaSurveyId: _vm.survey.id },
          }}},[_vm._v(" Classifier ")])],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"survey-edit-step__content"},[_c('h4',{staticClass:"survey-edit-step__title"},[_vm._v("Classify your photos")]),_c('div',{staticClass:"survey-edit-step__subtitle"},[_vm._v(" Nows your time to start tagging. ")])])}]

export { render, staticRenderFns }