


















































import {
  Component,
  Prop,
  InjectReactive,
  Vue,
  Watch,
} from 'vue-property-decorator';
import { FaunaMedia } from '@/api';

import CompactPagination from '@/components/upload/CompactPagination.vue';
import FaunaMediaThumb from '@/components/common/FaunaMediaThumb.vue';
import FilterButton from '@/components/common/FilterButton.vue';
import GridResize from '@/components/classifier/GridResize.vue';
import ClassifierImage from '@/components/classifier/ClassifierImage.vue';
import ClassifierImageInfo from '@/components/classifier/ClassifierImageInfo.vue';
import Classifier from '@/components/classifier/Classifier.vue';

import { numberFormat } from '@/util';

import Cookies from 'js-cookie';

@Component({
  components: {
    CompactPagination,
    FaunaMediaThumb,
    FilterButton,
    GridResize,
    ClassifierImage,
    ClassifierImageInfo,
  },
})
export default class ClassifierNav extends Vue {
  @Prop({ required: true }) readonly faunaMedia: FaunaMedia[];

  @Prop({ required: true }) readonly itemsPerPage: number;

  @Prop({ required: true }) readonly total: number;

  // used for v-model
  @Prop({ required: true }) readonly value: number;

  @InjectReactive() classifier: Classifier;

  smallThumbs = false;

  windowWidth = 0;

  gridWidth = 0;

  gridVW: number | null = null;

  get selected() {
    return this.value;
  }

  set selected(v: number) {
    this.classifier.autoProgress = false;
    this.$emit('input', v);
  }

  // stuff for the LHS grid nav
  get gridSize() {
    return this.smallThumbs ? 160 : 240;
  }

  get gridCols() {
    return Math.floor(this.gridWidth / this.gridSize);
  }

  get gridColStyle() {
    return `grid-template-columns: repeat(${this.gridCols}, 1fr);`;
  }

  get gridVWStyle() {
    return this.gridVW === null ? '' : `width:${this.gridVW}vw;`;
  }

  // find an id
  get containsId() {
    return (this.$route.query.id as string) || undefined;
  }

  set containsId(id: string | undefined) {
    this.$router.replace({
      query: { ...this.$route.query, id },
    });
  }

  get page() {
    if (this.containsId) {
      return -1;
    }
    return parseInt((this.$route.query.page as string) || '1', 10);
  }

  set page(p: number) {
    this.classifier.autoProgress = false;
    this.$router.replace({
      query: { ...this.$route.query, id: undefined, page: p.toString() },
    });
  }

  get pageCount() {
    return Math.ceil(this.total / this.itemsPerPage);
  }

  get displayCount() {
    return `Showing ${numberFormat(
      1 + (this.page - 1) * this.itemsPerPage,
    )} - ${numberFormat(
      Math.min(this.page * this.itemsPerPage, this.total),
    )} of ${numberFormat(this.total)}`;
  }

  scrollIntoView() {
    if (this.$refs.thumbs === undefined) {
      return;
    }
    const thumbs = this.$refs.thumbs as FaunaMediaThumb[];
    const thumb = thumbs && thumbs.length ? thumbs[this.selected] : null;
    if (thumb) {
      thumb.$el.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  keydown(e: KeyboardEvent) {
    // key down
    if (e.key === 'ArrowDown' || e.keyCode === 40) {
      e.preventDefault();
      this.selected = Math.min(
        this.selected + this.gridCols,
        this.faunaMedia.length - 1,
      );
      return;
    }

    // key up
    if (e.key === 'ArrowUp' || e.keyCode === 38) {
      e.preventDefault();
      this.selected = Math.max(this.selected - this.gridCols, 0);
      return;
    }

    // key down
    if (e.key === 'ArrowLeft' || e.keyCode === 37) {
      e.preventDefault();
      this.selected = Math.max(this.selected - 1, 0);
      return;
    }

    // key up
    if (e.key === 'ArrowRight' || e.keyCode === 39) {
      e.preventDefault();
      this.selected = Math.min(this.selected + 1, this.faunaMedia.length - 1);
    }
  }

  handleGridResize({ left }: { left: number; delta: number }) {
    this.gridVW = 100 * (left / window.innerWidth);
    this.resize();
  }

  handleGridResizeEnd() {
    if (this.gridVW === null) {
      Cookies.remove('grid-vw');
    } else {
      Cookies.set('grid-vw', this.gridVW.toString());
    }
  }

  toggleSmallThumbs() {
    this.smallThumbs = !this.smallThumbs;
    Cookies.set('grid-small-thumbs', this.smallThumbs.toString());
  }

  resize() {
    this.windowWidth = window.innerWidth;
    this.gridWidth = (this.$refs.grid as HTMLElement).offsetWidth;
    this.scrollIntoView();
  }

  async mounted() {
    const vwCookie = Cookies.get('grid-vw');
    const smallThumbsCookie = Cookies.get('grid-small-thumbs');
    if (vwCookie) {
      this.gridVW = parseInt(vwCookie, 10);
    }
    this.smallThumbs = smallThumbsCookie === 'true';

    this.$nextTick(this.resize);
  }

  created() {
    window.addEventListener('keydown', this.keydown);
    window.addEventListener('resize', this.resize);
  }

  beforeDestroy() {
    window.removeEventListener('keydown', this.keydown);
    window.removeEventListener('resize', this.resize);
  }

  @Watch('faunaMedia')
  faunaMediaChanged() {
    if (this.selected === -1) {
      this.selected = 0;
    }
  }

  @Watch('selected')
  @Watch('classifier.selectedItem')
  selectedChanged() {
    this.scrollIntoView();
  }
}
