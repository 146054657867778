















































import { Component, Vue, Watch } from 'vue-property-decorator';
import { SurveyProperty } from '@/api';
import PageHeader from '@/components/common/PageHeader.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import FilterButtonContainer from '@/components/common/FilterButtonContainer.vue';
import FilterItem from '@/components/common/FilterItem.vue';
import AbstractTable from '@/components/common/AbstractTable.vue';
import cacheModule from '@/store/Cache';
import { isEqual } from 'lodash';

@Component({
  components: {
    PageHeader,
    AbstractTable,
    FilterButtonContainer,
    FilterItem,
    TableMenuButton,
  },
})
export default class AdminPropertiesList extends Vue {
  get headers() {
    return SurveyProperty.listHeaders();
  }

  get scopeFactory() {
    return () => SurveyProperty.scopeFactory().where(this.whereClause);
  }

  get filterItems() {
    return [
      {
        label: 'Focal Landscape',
        relationship: 'focalLandscape',
        queryParam: 'focal-landscape',
        items: cacheModule.focalLandscapeFilterItems,
      },
    ];
  }

  get whereClause() {
    const clause: { [key: string]: unknown } = {};
    this.filterItems.forEach(filterItem => {
      clause[filterItem.relationship] =
        this.$route.query[filterItem.queryParam] || undefined;
    });
    return { ...clause, search: this.search };
  }

  search = '';

  click(item: SurveyProperty) {
    this.$router.push({
      name: 'admin-properties-edit',
      params: { itemId: item.id as string },
    });
  }

  mounted() {
    cacheModule.getFocalLandscapes();
  }
}
