





















































import authModule from '@/store/Auth';
import Cookies from 'js-cookie';
import { Component, VModel, Vue, Watch } from 'vue-property-decorator';
import dayjs from 'dayjs';
import SidebarInfo from './SidebarInfo.vue';

@Component({
  components: {
    SidebarInfo,
  },
})
export default class WelcomeDialog extends Vue {
  @VModel() show: boolean;

  isPublic = false;

  username = '';

  skip() {
    Cookies.set('wt-profile', 'true', {
      expires: dayjs()
        .add(30, 'days')
        .toDate(),
    });
    this.show = false;
  }

  save() {
    authModule.setProfileInfo({
      username: this.username,
      isPublic: this.isPublic,
    });
  }

  @Watch('show', { immediate: true })
  showChanged() {
    this.username = authModule.user ? authModule.user.name : '';
    this.isPublic = !!authModule.user && authModule.user.isPublic;
  }
}
