


























import { Component, Prop, Vue } from 'vue-property-decorator';

import ItemBreadcrumb from '@/components/common/ItemBreadcrumb.vue';
import BboxStatus from '@/components/common/BboxStatus.vue';
import BboxPreview from '@/components/common/BboxPreview.vue';
import BboxTags from '@/components/property/BboxTags.vue';

import { DetectionBbox } from '@/api';

import authModule from '@/store/Auth';
import userModule from '@/store/User';

@Component({
  components: {
    ItemBreadcrumb,
    BboxStatus,
    BboxPreview,
    BboxTags,
  },
})
export default class BboxListItem extends Vue {
  @Prop({ required: true }) readonly bbox: DetectionBbox;

  loading = false;

  get isAdmin() {
    return authModule.isAdmin;
  }

  get surveyId() {
    return this.bbox.faunaMedia.faunaSurvey.id;
  }

  get surveySiteId() {
    return this.bbox.faunaMedia.faunaSurvey.surveySite.id;
  }

  get property() {
    if (!this.surveySiteId) {
      return null;
    }
    return userModule.propertyBySiteId(this.surveySiteId);
  }

  get site() {
    const site =
      this.property &&
      this.property.surveySites.find(s => s.id === this.surveySiteId);

    return site;
  }

  get bboxClassifierLink() {
    if (!this.property) {
      console.warn('need a property to get the link');
      return null;
    }
    return {
      name: 'classifier',
      params: { propertyId: this.property.id, faunaSurveyId: this.surveyId },
      query: { id: this.bbox.faunaMedia.id },
    };
  }

  mounted() {
    if (this.surveySiteId) {
      userModule.getPropertyBySiteId(this.surveySiteId);
    }
  }
}
