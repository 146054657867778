




import { Component, Vue, Prop } from 'vue-property-decorator';
import { SurveyProperty, FaunaSurvey } from '@/api';

@Component({
  components: {},
})
export default class SurveyEdit extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ required: true }) readonly survey: FaunaSurvey;

  mounted() {
    // TODO: if the previous route was the Classifier, go to the survey list

    this.$router.replace({
      name: 'classifier',
      params: {
        propertyId: this.property.id as string,
        faunaSurveyId: this.survey.id as string,
      },
    });
  }
}
