












































































import { Component, Prop, Vue } from 'vue-property-decorator';

import PageHeader from '@/components/common/PageHeader.vue';
import GetStartedSite from '@/components/common/GetStartedSite.vue';
import GetStartedSurvey from '@/components/common/GetStartedSurvey.vue';
import PropertyOutline from '@/components/property/PropertyOutline.vue';
import TableMenuButton from '@/components/common/TableMenuButton.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import StatBtn from '@/components/common/StatBtn.vue';

import { SurveyProperty } from '@/api';
import authModule from '@/store/Auth';
import propertyModule from '@/store/Property';

import { numberFormat } from '@/util';

@Component({
  components: {
    PageHeader,
    PropertyOutline,
    GetStartedSite,
    GetStartedSurvey,
    TableMenuButton,
    UserAvatar,
    StatBtn,
  },
})
export default class PropertyListItem extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get isAdmin() {
    return authModule.isAdmin;
  }

  get numberFormat() {
    return numberFormat;
  }
}
