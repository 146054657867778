import { Model, Attr, HasMany } from 'spraypaint';
import i18n from '@/plugins/vue-i18n';
import dayjs from 'dayjs';
import { Program } from '@/api';
import ApplicationRecord from '../ApplicationRecord';

export enum UserRole {
  admin = 'Ionata Admins',
  manager = 'Project Managers',
  assessor = 'Assessors',
  verifier = 'Verifiers',
  owner = 'Owners',
}

@Model()
export default class User extends ApplicationRecord {
  static jsonapiType = 'users';

  static async register(email: string, password: string) {
    const user = new User({ email, password });
    await user.save();
    return user;
  }

  static listHeaders() {
    return [
      {
        text: i18n.t('common.labels.id'),
        value: 'id',
        width: 70,
      },
      {
        text: i18n.t('users.labels.fullName'),
        value: 'name',
      },
      {
        text: i18n.t('common.labels.email'),
        value: 'email',
      },
      {
        text: 'Owner',
        value: 'isOwner',
      },
      {
        text: 'Property Users',
        value: 'propertyUsersCount',
      },
      {
        text: 'Privacy',
        value: 'privacy',
      },
      {
        text: 'Joined',
        value: 'joined',
      },
      {
        text: 'Terms Agreed',
        value: 'termsAgreedFormatted',
      },
    ];
  }

  static scopeFactory() {
    return User.includes([]).order({ dateJoined: 'desc' });
  }

  @Attr() dateJoined: string;

  @Attr() name: string;

  @Attr() email: string;

  @Attr() isPublic: boolean;

  // write only
  @Attr() password: string;

  // write only
  @Attr() currentPassword: string;

  @Attr() avatar: string;

  @Attr() readonly isOwner: boolean;

  @Attr() readonly propertyUsersCount: number;

  // datetime
  @Attr() termsAgreed: string;

  @Attr() roles: string[];

  @HasMany() programs: Program[];

  changePassword(currentPassword: string, newPassword: string) {
    const user = new User({
      id: this.id,
      password: newPassword,
      currentPassword,
    });
    return user.save();
  }

  get avatarUrl() {
    return this.avatar
      ? this.avatar
      : `https://api.dicebear.com/7.x/pixel-art/svg?seed=user${this.id}&scale=70`;
  }

  get joined() {
    return `${dayjs(this.dateJoined).format('MMMM D, YYYY')}`;
  }

  get termsAgreedFormatted() {
    return this.termsAgreed
      ? `${dayjs(this.termsAgreed).format('MMMM D, YYYY')}`
      : '-';
  }

  get privacy() {
    return this.isPublic ? 'Public user' : 'Private user';
  }
}
