var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"property-list-item"},[_c('router-link',{staticClass:"property-list-item__property-outline",attrs:{"to":{
      name: 'property-overview',
      params: { propertyId: _vm.property.id },
    }}},[_c('property-outline',{attrs:{"property":_vm.property}})],1),_c('div',{staticClass:"property-list-item__content"},[_c('div',{staticClass:"property-list-item__title"},[_c('div',{staticClass:"d-flex align-center gap-4"},[_c('user-avatar',{attrs:{"name":"small","user":_vm.property.owner,"highlight-me":true},scopedSlots:_vm._u([{key:"name",fn:function(ref){
    var user = ref.user;
return [_c('div',{staticClass:"ml-1"},[_c('router-link',{staticClass:"list-item-title-link",attrs:{"to":{
                  name: 'property-overview',
                  params: { propertyId: _vm.property.id },
                }}},[_c('h4',[_vm._v(_vm._s(_vm.property.name))])]),_c('div',{staticClass:"text--small"},[_vm._v(" Owned by "),_c('strong',[_vm._v(_vm._s(user ? user.name : 'Unknown'))]),_vm._v(" • Established "+_vm._s(_vm.property.established)+" ")])],1)]}}])})],1),_c('div',{staticClass:"property-list-item__title-actions"},[(_vm.isAdmin)?_c('table-menu-button',{attrs:{"icon":"mdi-dots-horizontal","tooltip":"More Actions","menuDense":true}},[_c('v-list-item',{on:{"click":function($event){return _vm.$emit('deleteItem')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"red--text"},[_vm._v("Delete")])])],1)],1)],1):_vm._e()],1)]),_c('div',{staticClass:"stat-btn-grid"},[_c('stat-btn',{attrs:{"icon":"mdi-map-marker-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.property.surveySites.length))+" sites ")]),_c('stat-btn',{attrs:{"icon":"mdi-image-multiple-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.property.surveyAssessed))+" of "+_vm._s(_vm.numberFormat(_vm.property.surveyCount))+" surveys completed ")]),_c('stat-btn',{attrs:{"icon":"mdi-camera-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.property.propertyFaunaMediaCount))+" photos ")]),_c('stat-btn',{attrs:{"icon":"mdi-tag-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.property.totalBboxCount))+" animals found ")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }