















































import { Component, Vue, Watch } from 'vue-property-decorator';
import PageHeader from '@/components/common/PageHeader.vue';
import AbstractTable from '@/components/common/AbstractTable.vue';
import FilterButtonContainer from '@/components/common/FilterButtonContainer.vue';
import FilterButton from '@/components/common/FilterButton.vue';
import SortButton from '@/components/common/SortButton.vue';

import { User } from '@/api';

@Component({
  components: {
    PageHeader,
    AbstractTable,
    FilterButtonContainer,
    FilterButton,
    SortButton,
  },
})
export default class AdminUsersList extends Vue {
  get headers() {
    return User.listHeaders();
  }

  get scopeFactory() {
    return User.scopeFactory;
  }

  get abstractTable() {
    return this.$refs.table as AbstractTable;
  }

  get search() {
    return (this.$route.query.search as string) || '';
  }

  set search(s: string) {
    this.$router.replace({
      query: {
        ...this.$route.query,
        search: s,
        page: undefined,
      },
    });
  }

  get whereClause() {
    return { search: this.search };
  }

  click(item: User) {
    this.$router.push({
      name: 'admin-users-edit',
      params: { itemId: item.id as string },
    });
  }
}
