





































































































import { Component, Vue, Watch } from 'vue-property-decorator';

import PageHeader from '@/components/common/PageHeader.vue';
import PropertySwap from '@/components/property/PropertySwap.vue';
import NotFound from '@/components/common/NotFound.vue';

import UserAvatar from '@/components/common/UserAvatar.vue';

import propertyModule from '@/store/Property';
import authModule from '@/store/Auth';

import { numberFormat } from '@/util';

@Component({
  components: {
    UserAvatar,
    PageHeader,
    PropertySwap,
    NotFound,
  },
})
export default class PropertySingleView extends Vue {
  get propertyId() {
    return this.$route.params.propertyId;
  }

  get property() {
    return propertyModule.property(this.propertyId);
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get propertySurveyCount() {
    return propertyModule.propertySurveyCount(this.propertyId);
  }

  get propertyFaunaCount() {
    return this.property ? this.property.propertyFaunaMediaCount : 0;
  }

  get propertyFavouriteCount() {
    return propertyModule.propertyFavouriteCount(this.propertyId);
  }

  get numberFormat() {
    return numberFormat;
  }

  isNotFound = false;

  refreshStats() {
    propertyModule.getPropertyTagStats(this.propertyId);
    propertyModule.getPropertySurveyStats(this.propertyId);
    propertyModule.getPropertyInvasiveStats(this.propertyId);
    propertyModule.getPropertyFavourites(this.propertyId);
  }

  @Watch('propertyId', { immediate: true })
  async propertyIdChanged() {
    try {
      await propertyModule.getProperty(this.propertyId);
      this.refreshStats();
      authModule.setDefaultPropertyId(this.propertyId);
    } catch (e) {
      this.isNotFound = true;
    }
  }
}
