
















































































































































































































































import {
  Component,
  Vue,
  Prop,
  VModel,
  InjectReactive,
} from 'vue-property-decorator';

import { DetectionBbox, TagStatusType } from '@/api';

import authModule from '@/store/Auth';
import cacheModule from '@/store/Cache';

import Classifier from '@/components/classifier/Classifier.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';
import BboxClassificationData from '@/components/classifier/BboxClassificationData.vue';

@Component({
  components: {
    UserAvatar,
    BboxClassificationData,
  },
})
export default class BboxDialog extends Vue {
  @Prop({ default: null }) readonly bbox: DetectionBbox | null;

  @VModel() show: boolean;

  @InjectReactive() classifier: Classifier;

  loading = false;

  get isAdmin() {
    return authModule.isAdmin;
  }

  get title() {
    if (!this.bbox) {
      return 'Unknown';
    }
    switch (this.bbox.tagStatus) {
      case TagStatusType.none:
        return 'No Tag Chosen';
      case TagStatusType.conflict:
        return 'Ooops! We have a mismatch';
      case TagStatusType.agreed:
        return 'Nice work! We have a match';
      case TagStatusType.resolved:
        return 'Expert assessment';
      default:
        return 'Unknown';
    }
  }

  get titleIcon() {
    if (!this.bbox) {
      return '';
    }
    switch (this.bbox.tagStatus) {
      case TagStatusType.conflict:
        return 'mdi-alert-circle';
      case TagStatusType.agreed:
        return 'mdi-check-circle';
      case TagStatusType.resolved:
        return 'mdi-school';
      default:
        return 'mdi-help';
    }
  }

  get userTag() {
    return this.bbox && this.bbox.userTag
      ? {
          faunaMediaTag: this.bbox.userTag,
          faunaTag: cacheModule.faunaTagsById(
            this.bbox.userTag.faunaTag.id as string,
          ),
        }
      : null;
  }

  get aiTag() {
    return this.bbox && this.bbox.aiTag
      ? {
          faunaMediaTag: this.bbox.aiTag,
          faunaTag: cacheModule.faunaTagsById(
            this.bbox.aiTag.faunaTag.id as string,
          ),
        }
      : null;
  }

  get expertTag() {
    return this.bbox && this.bbox.expertTag
      ? {
          faunaMediaTag: this.bbox.expertTag,
          faunaTag: cacheModule.faunaTagsById(
            this.bbox.expertTag.faunaTag.id as string,
          ),
        }
      : null;
  }

  get resolvedTag() {
    return this.bbox && this.bbox.resolvedTag
      ? {
          faunaMediaTag: this.bbox.resolvedTag,
          faunaTag: cacheModule.faunaTagsById(
            this.bbox.resolvedTag.faunaTag.id as string,
          ),
        }
      : null;
  }

  get aiConfidence() {
    return this.bbox ? this.bbox.aiConfidence * 100 : 0;
  }

  get showReviewBtn() {
    // TODO: need to chek is assessor rather than isadmin?
    return (
      !this.isAdmin &&
      this.bbox &&
      this.bbox.tagStatus === TagStatusType.conflict
    );
  }

  deleteUserTag() {
    if (!this.userTag) {
      return;
    }
    this.$emit('delete-tag', this.userTag.faunaMediaTag);
    this.show = false;
  }
}
