











































import {
  Component,
  Vue,
  Prop,
  VModel,
  Watch,
  InjectReactive,
} from 'vue-property-decorator';

import { FaunaSurvey, SurveyProperty, faunaSurveyStatusItems } from '@/api';

import Classifier from '@/components/classifier/Classifier.vue';

@Component({
  components: {},
})
export default class ChangeStatusDialog extends Vue {
  @Prop({ required: true }) readonly survey: FaunaSurvey;

  @VModel() show: boolean;

  @InjectReactive() classifier: Classifier;

  property: SurveyProperty | null = null;

  surveyToSave = new FaunaSurvey();

  get propertyId() {
    const id = this.$route.params.propertyId;
    if (Number.isNaN(parseInt(id, 10))) {
      throw new Error('Invalid property id');
    }
    return id;
  }

  get users() {
    return this.property
      ? [this.property.owner, ...this.property.propertyUsers.map(pu => pu.user)]
      : [];
  }

  get faunaSurveyStatus() {
    return faunaSurveyStatusItems;
  }

  async saveSurvey() {
    if (!this.surveyToSave.id) {
      console.warn('existing survey needed to save');
      return;
    }
    try {
      await this.surveyToSave.save({ with: ['assessedBy.id'] });
      this.classifier.snack.setSuccess('Survey status changed');
      this.show = false;
    } catch (e) {
      this.classifier.snack.setError({
        text: 'Could not save survey',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  async getProperty() {
    try {
      this.property = (
        await SurveyProperty.select(['id', 'name', 'propertyUsers', 'owner'])
          .includes(['propertyUsers.user', 'owner'])
          .find(this.propertyId)
      ).data;
    } catch (e) {
      this.classifier.snack.setError({
        text: 'Could not get property',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  mounted() {
    this.getProperty();
  }

  @Watch('show', { immediate: true })
  showChanged() {
    if (this.show) {
      this.surveyToSave = this.survey.dup();
    }
  }
}
