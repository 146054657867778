




























import { Component, Vue, Prop } from 'vue-property-decorator';
import { Location } from 'vue-router';

@Component({ components: {} })
export default class PageHeader extends Vue {
  @Prop({ required: false, default: false }) readonly back: Location | boolean;

  @Prop({ default: '' }) readonly title: string;

  @Prop({ default: '' }) readonly subtitle: string;

  @Prop({ default: false }) readonly fluid: boolean;
}
