var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"property-stats"},[_c('stat-tile',{attrs:{"title":"Surveys created","subtitle":((_vm.property.surveyAssessed) + " completed"),"value":_vm.property.surveyAssessed,"max":_vm.property.surveyCount,"to":{
      name: 'property-surveys',
      params: { propertyId: _vm.property.id },
    }}}),_c('stat-tile',{attrs:{"title":"Photos uploaded","subtitle":((_vm.numberFormat(_vm.property.propertyFaunaMediaCount)) + " uploaded"),"value":_vm.property.propertyFaunaMediaCount,"max":_vm.property.propertyFaunaMediaCount,"to":{
      name: 'property-tags',
      params: { propertyId: _vm.property.id },
    }}}),_c('stat-tile',{attrs:{"title":"Animals found","subtitle":((_vm.numberFormat(
        _vm.property.totalBboxCount - _vm.property.tagStatusNone
      )) + " identified"),"value":_vm.property.totalBboxCount - _vm.property.tagStatusNone,"max":_vm.property.totalBboxCount,"to":{
      name: 'property-tags',
      params: { propertyId: _vm.property.id },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }