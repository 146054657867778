

























import { Component, Vue, Prop } from 'vue-property-decorator';
import { SurveyProperty, FaunaSurvey, FaunaSurveyStatus } from '@/api';

import dayjs from 'dayjs';

// TODO: handle no sites
import GetStartedSite from '@/components/common/GetStartedSite.vue';
import SurveyStepEdit from '@/components/survey/SurveyStepEdit.vue';
import SurveyStepPublished from '@/components/survey/SurveyStepPublished.vue';
import SurveyStepOther from '@/components/survey/SurveyStepOther.vue';

import { numberFormat } from '@/util';
import snackModule from '@/store/Snack';

import { NavigationGuardNext, Route } from 'vue-router';

let fromClassifier = false;

@Component({
  components: {
    GetStartedSite,
    SurveyStepEdit,
    SurveyStepPublished,
    SurveyStepOther,
  },
})
export default class SurveyEdit extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  survey: FaunaSurvey | null = null;

  predictedCountInterval: number | null = null;

  updatedTs = '';

  // TODO: handle not found
  notFound = false;

  get surveyId() {
    return this.$route.params.surveyId;
  }

  get createMode() {
    return this.$route.name === 'property-surveys-create';
  }

  get numberFormat() {
    return numberFormat;
  }

  /**
   * how many have been predicted
   */
  get predictedCount() {
    return this.survey ? this.survey.snsStatusPredicted : 0;
  }

  /**
   * how many have been detected
   */
  get detectedCount() {
    return this.survey
      ? this.survey.snsStatusPredicted + this.survey.snsStatusDetected
      : 0;
  }

  /**
   * how many successfully uploaded photos are in the survey
   */
  get totalCount() {
    return this.survey ? this.survey.faunaMediaCount : 0;
  }

  /**
   * is the survey a draft?
   */
  get isDraft() {
    return this.survey && this.survey.status === FaunaSurveyStatus.draft;
  }

  /**
   * is the survey published?
   */
  get isPublished() {
    return this.survey && this.survey.status === FaunaSurveyStatus.published;
  }

  /**
   * is the survey still processing?
   */
  get isProcessing() {
    return this.predictedCount < this.totalCount;
  }

  /**
   * get the updated survey
   */
  async getUpdatedSurvey() {
    this.updatedTs = dayjs().format('h:mm A');

    // setup a survey if one doesnt exist
    if (!this.surveyId) {
      if (!this.survey) {
        this.survey = new FaunaSurvey({
          status: FaunaSurveyStatus.draft,
        });
      }
      return;
    }

    try {
      // fetch the survey from the server
      const survey = (await FaunaSurvey.find(this.surveyId)).data;
      this.survey = survey;
    } catch (e) {
      snackModule.setError({
        text: 'Error retrieving survey',
        errors: (e as ErrorResponse).response.errors,
      });
      this.notFound = true;
    }
  }

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    fromClassifier = from.name === 'classifier';
    next();
  }

  async mounted() {
    // periodically get the predicted count if still processing
    this.predictedCountInterval = setInterval(() => {
      if (this.isProcessing) {
        this.getUpdatedSurvey();
      }
    }, 5000);

    // get the survey
    await this.getUpdatedSurvey();

    // I shouldnt be here if its not draft or published
    // so redirect to either the classifier or the surveys page
    if (
      this.survey &&
      ![FaunaSurveyStatus.draft, FaunaSurveyStatus.published].includes(
        this.survey.status,
      )
    ) {
      if (fromClassifier) {
        this.$router.replace({
          name: 'property-surveys',
          params: { propertyId: this.property.id as string },
        });
      } else {
        this.$router.replace({
          name: 'classifier',
          params: {
            propertyId: this.property.id as string,
            faunaSurveyId: this.survey.id as string,
          },
        });
      }
    }
  }
}
