var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.property)?_c('div',{staticClass:"property-single-view"},[_c('page-header',{scopedSlots:_vm._u([{key:"heading",fn:function(){return [_c('div',{staticClass:"d-flex gap-4 align-center"},[_c('user-avatar',{attrs:{"user":_vm.property.owner}}),_c('h1',{staticClass:"h-display"},[_vm._v(_vm._s(_vm.property.name))]),_c('property-swap')],1)]},proxy:true}],null,false,1026868843)},[_c('v-tabs',{attrs:{"background-color":"transparent","hide-slider":""}},[_c('v-tab',{attrs:{"to":{
          name: 'property-overview',
          params: { propertyId: _vm.propertyId },
        },"exact-path":""}},[_vm._v(" Overview "),_c('v-chip',{staticClass:"ml-2",attrs:{"color":"primary white--text","small":""}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-finance")])],1)],1),_c('v-tab',{attrs:{"to":{
          name: 'property-surveys',
          params: { propertyId: _vm.propertyId },
        }}},[_vm._v(" Surveys "),_c('v-chip',{staticClass:"ml-2",attrs:{"color":"blue white--text","small":""}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.propertySurveyCount))+" ")])],1),_c('v-tab',{attrs:{"to":{
          name: 'property-tags',
          params: { propertyId: _vm.propertyId },
        },"exact-path":""}},[_vm._v(" Photos "),_c('v-chip',{staticClass:"ml-2",attrs:{"color":"teal white--text","small":""}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.propertyFaunaCount))+" ")])],1),_c('v-tab',{attrs:{"to":{
          name: 'property-favourites',
          params: { propertyId: _vm.propertyId },
          query: { 'has-favourites': 'true' },
        },"exact-path":""}},[_vm._v(" Favourites "),_c('v-chip',{staticClass:"ml-2",attrs:{"color":"red white--text","small":""}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.propertyFavouriteCount))+" ")])],1),_c('v-tab',{attrs:{"to":{
          name: 'property-users',
          params: { propertyId: _vm.propertyId },
        },"exact-path":""}},[_vm._v(" Users "),_c('v-chip',{staticClass:"ml-2",attrs:{"color":"purple white--text","small":""}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.property.propertyUsers.length))+" ")])],1),_c('v-tab',{attrs:{"to":{
          name: 'property-stickybeak',
          params: { propertyId: _vm.propertyId },
        },"exact-path":""}},[_c('img',{staticClass:"mr-2",attrs:{"src":"/stickybeak-avatar.svg","width":"32","alt":""}}),_vm._v(" Stickybeak ")])],1)],1),_c('v-container',[_c('router-view',{attrs:{"property":_vm.property}})],1)],1):(_vm.isNotFound)?_c('not-found'):_c('div',{staticClass:"d-flex justify-center align-center"},[_c('v-progress-circular',{attrs:{"color":"primary","indeterminate":"","size":"56"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }