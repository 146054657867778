var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"classifier-drawer-bottom d-flex align-center justify-center gap-2"},[_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","dark":"","large":"","ripple":false},on:{"click":_vm.classifier.prevImage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-skip-previous ")])],1)]}}])},[_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v(" Previous ")]),_c('div',[_vm._v(" Jump to the previous photo that needs attention ")])])]),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","dark":"","large":"","ripple":false,"color":_vm.classifier.autoProgress ? 'primary' : ''},on:{"click":_vm.setAutoProgress}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-debug-step-over ")])],1)]}}])},[_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v(" Auto Navigate ")]),_c('div',[_vm._v(" Automatically jump to the next photo that needs attention ")])])]),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","dark":"","large":"","ripple":false},on:{"click":_vm.classifier.nextImage}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-skip-next ")])],1)]}}])},[_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v(" Next ")]),_c('div',[_vm._v(" Jump to the next photo that needs attention ")])])]),_c('div',{staticClass:"classifier-drawer-bottom__divider"}),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","dark":"","large":"","ripple":false,"color":_vm.classifier.showFilters ? 'primary' : ''},on:{"click":function($event){_vm.classifier.showFilters = !_vm.classifier.showFilters}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-filter ")])],1)]}}])},[_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v(" Filters ")]),_c('div',[_vm._v(" Show filters ")])])]),_c('div',{staticClass:"classifier-drawer-bottom__divider"}),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","dark":"","large":"","ripple":false,"color":_vm.userFavourite ? 'red' : ''},on:{"click":_vm.toggleFavourite}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-heart ")]),(_vm.item.favouriteCount)?_c('div',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(_vm.item.favouriteCount)+" ")]):_vm._e()],1)]}}])},[_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v("Favourite")]),_c('div',[_vm._v("Add this photo to your favourites")])])]),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","dark":"","large":"","ripple":false,"color":!!_vm.item.comment ? 'primary' : ''},on:{"click":function($event){_vm.classifier.showCommentDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-comment ")])],1)]}}])},[_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v("Comment")]),_c('div',[_vm._v("Add a comment to this photo")])])]),_c('v-tooltip',{attrs:{"top":"","color":"grey darken-4","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"depressed":"","dark":"","large":"","ripple":false,"color":_vm.userFavourite && _vm.userFavourite.isShared ? 'primary' : ''},on:{"click":_vm.toggleShare}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-share ")])],1)]}}])},[_c('div',{staticClass:"text-center"},[_c('strong',[_vm._v("Share")]),_c('div',[_vm._v("Enable sharing of this photo")])])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }