




import { Component, Vue } from 'vue-property-decorator';

import { NavigationGuardNext, Route } from 'vue-router';

import authModule from '@/store/Auth';

// this component is just a stub to redirect to the first property

@Component({
  components: {},
})
export default class PropertyStub extends Vue {
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    console.log(to.name);
    if (!authModule.isAdmin) {
      let propertyId = authModule.defaultPropertyId;
      if (!propertyId) {
        if (authModule.ownedProperties.length) {
          propertyId = authModule.ownedProperties[0].id as string;
        } else if (authModule.propertyUsers.length) {
          propertyId = authModule.propertyUsers[0].surveyProperty.id as string;
        } else {
          console.warn('no property!');
          next();
          return;
        }
      }
      next({
        name: 'property-overview',
        params: { propertyId },
      });
    } else {
      next({
        name: 'admin-dashboard-properties',
      });
    }
  }
}
