




































import { Component, Vue, Watch } from 'vue-property-decorator';
import { JsonapiError } from 'spraypaint';

@Component({
  components: {},
})
export default class ClassifierSnack extends Vue {
  showErrorSnackbar = false;

  errors: JsonapiError[] = [];

  snackText = '';

  snackType: 'error' | 'warning' | 'success' | 'none' = 'none';

  get snackbarIcon() {
    switch (this.snackType) {
      case 'error':
        return 'mdi-alert-circle';
      case 'warning':
        return 'mdi-alert-circle-outline';
      case 'success':
        return 'mdi-check-circle';
      default:
        return '';
    }
  }

  setError({
    text,
    errors,
  }: {
    text: string;
    errors: JsonapiError[] | undefined;
  }) {
    this.snackText = text;
    this.errors = errors || [];
    this.snackType = 'error';
  }

  setWarning(errors: JsonapiError[]) {
    this.snackText = 'Whoops';
    this.errors = errors;
    this.snackType = 'warning';
  }

  setSuccess(text: string) {
    this.snackText = text;
    this.errors = [];
    this.snackType = 'success';
  }

  @Watch('errors')
  snackMessageChanged() {
    this.showErrorSnackbar = true;
  }
}
