













































import { Component, Vue } from 'vue-property-decorator';

import { SurveyProperty } from '@/api';
import authModule from '@/store/Auth';

@Component({ components: {} })
export default class SortButton extends Vue {
  showMenu = false; // menu

  items: SurveyProperty[] = [];

  get selected() {
    return this.$route.params.propertyId;
  }

  get isAdmin() {
    return authModule.isAdmin;
  }

  get propertyUsers() {
    return authModule.propertyUsers;
  }

  selectProperty(property: SurveyProperty) {
    this.$router.push({
      name: 'property-overview',
      params: { propertyId: property.id as string },
    });
  }

  async mounted() {
    // TODO: use the property module
    if (this.isAdmin) {
      this.items = (
        await SurveyProperty.select(['id', 'name'])
          .per(1000)
          .all()
      ).data;
    } else {
      this.items = [
        ...authModule.ownedProperties,
        ...authModule.propertyUsers.map(pu => pu.surveyProperty),
      ];
    }
  }
}
