






























































import { Component, Vue, Prop } from 'vue-property-decorator';
import CardInfo from '@/components/common/CardInfo.vue';
import UserAvatar from '@/components/common/UserAvatar.vue';

import { SurveyProperty } from '@/api';

@Component({
  components: {
    UserAvatar,
    CardInfo,
  },
})
export default class PropertyCard extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get user() {
    return this.property.owner;
  }
}
