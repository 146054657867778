






























































































































































































import { Component, Vue, Prop, InjectReactive } from 'vue-property-decorator';

import {
  FaunaMedia,
  FaunaSurvey,
  FaunaSurveyStatus,
  SurveyProperty,
  UserFavourite,
} from '@/api';

import authModule from '@/store/Auth';

import UserAvatar from '@/components/common/UserAvatar.vue';
import Classifier from '@/components/classifier/Classifier.vue';
import ClassifierAdminActions from '@/components/classifier/ClassifierAdminActions.vue';

import shareDialog from '@/share-dialog';

@Component({
  components: {
    UserAvatar,
    ClassifierAdminActions,
  },
})
export default class ClassifierDrawerBottom extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  @Prop({ required: true }) readonly survey: FaunaSurvey;

  @Prop({ required: true }) readonly item: FaunaMedia;

  @InjectReactive() classifier: Classifier;

  loading = false;

  get userFavourite() {
    return this.item && authModule.userFavouriteById(this.item.id as string);
  }

  get statusTitle() {
    switch (this.classifier.faunaSurveyStatus) {
      case FaunaSurveyStatus.draft:
        return 'Draft';
      case FaunaSurveyStatus.published:
        return 'Ready for tagging';
      case FaunaSurveyStatus.inProgress:
        return 'Tagging in progress';
      case FaunaSurveyStatus.assessed:
        return 'Tagging complete';
      default:
        return 'Unknown';
    }
  }

  setAutoProgress() {
    this.classifier.autoProgress = !this.classifier.autoProgress;
    if (this.classifier.autoProgress) {
      this.classifier.findNext();
    }
  }

  async getFaunaMediaFavourites() {
    if (!authModule.user || !authModule.user.id) {
      return;
    }
    const fm = (
      await FaunaMedia.includes(['userFavourites.user']).find(
        this.item.id as string,
      )
    ).data;
    this.item.adjustedFavouriteCount = fm.favouriteCount;
  }

  async toggleFavourite() {
    try {
      if (this.userFavourite) {
        await this.userFavourite.destroy();
      } else {
        const userFav = new UserFavourite({
          faunaMedia: this.item,
          user: authModule.user,
        });
        await userFav.save({ with: ['faunaMedia.id', 'user.id'] });
      }
      this.getFaunaMediaFavourites();
      authModule.getUserFavourites();
      this.classifier.refreshFaunaMedia(this.item.id as string);
    } catch (e) {
      this.classifier.snack.setError({
        text: 'Error saving Favourite',
        errors: (e as ErrorResponse).response.errors,
      });
    }
  }

  async toggleShare() {
    await shareDialog(this.item, this.userFavourite);
    this.getFaunaMediaFavourites();
    authModule.getUserFavourites();
  }
}
