







import { Component, Vue, Prop } from 'vue-property-decorator';
import { FaunaSurvey, FaunaSurveyStatus } from '@/api';

@Component({})
export default class FaunaSurveyChip extends Vue {
  @Prop({ required: true }) readonly survey: FaunaSurvey;

  get statusChip() {
    const status = this.survey ? this.survey.status : '';
    const isProcessing = this.survey ? this.survey.isProcessing : false;

    if (status === FaunaSurveyStatus.draft) {
      return {
        title: 'Draft',
        icon: 'mdi-clock-outline',
        color: 'grey',
      };
    }
    if (status === FaunaSurveyStatus.published && isProcessing) {
      return {
        title: 'Processing',
        icon: 'mdi-creation',
        color: 'grey',
      };
    }
    if (status === FaunaSurveyStatus.published) {
      return {
        title: 'Needs Classification',
        icon: 'mdi-alert-circle-outline',
        color: 'blue-grey',
      };
    }
    if (status === FaunaSurveyStatus.inProgress) {
      return {
        title: 'In Progress',
        icon: 'mdi-tag-outline',
        color: 'blue',
      };
    }
    if (
      status === FaunaSurveyStatus.assessed ||
      status === FaunaSurveyStatus.verified
    ) {
      return {
        title: 'Complete',
        icon: 'mdi-check-circle',
        color: 'green',
      };
    }

    return {
      title: 'Unknown',
      icon: 'mdi-help-circle',
      color: 'secondary',
    };
  }
}
