





















































import {
  Component,
  Vue,
  Prop,
  Watch,
  InjectReactive,
} from 'vue-property-decorator';

import BboxDialog from '@/components/classifier/BboxDialog.vue';
import BboxItem from '@/components/classifier/BboxItem.vue';
import BboxContextMenu from '@/components/classifier/BboxContextMenu.vue';
import Classifier from '@/components/classifier/Classifier.vue';

import {
  FaunaMedia,
  FaunaMediaTag,
  FaunaTag,
  DetectionBbox,
  SurveyProperty,
  FaunaSurvey,
  TaggerType,
} from '@/api';

import confirmDialog from '@/confirm-dialog';

@Component({
  components: {
    BboxDialog,
    BboxItem,
    BboxContextMenu,
  },
})
export default class ClassifierImage extends Vue {
  @Prop({ required: true }) readonly item: FaunaMedia;

  @Prop({ required: true }) readonly bboxes: DetectionBbox[];

  @Prop({ required: true }) readonly survey: FaunaSurvey;

  @Prop({ required: true }) readonly property: SurveyProperty;

  @InjectReactive() classifier: Classifier;

  parentW = 0;

  parentH = 0;

  natW = 0;

  natH = 0;

  imgW = 0;

  imgH = 0;

  imgX = 0;

  imgY = 0;

  zoomPosX = 0;

  zoomPosY = 0;

  showZoomLayer = false;

  zoomActive = false;

  observer: ResizeObserver;

  zoomFactor = 3;

  contextMenuActive = false;

  contextMenuX = 0;

  contextMenuY = 0;

  activeBbox: DetectionBbox | null = null;

  bboxDialogActive = false;

  triggerActionComplete = false;

  imageLoaded = false;

  /**
   * the image url
   */
  get src() {
    return this.item.serve;
  }

  /**
   * resize and position the image
   */
  get style() {
    return `width:${this.imgW}px; height:${this.imgH}px; left:${this.imgX}px; top:${this.imgY}px;`;
  }

  /**
   * zoom viewfinder width
   */
  get zoomW() {
    return this.imgW / this.zoomFactor;
  }

  /**
   * zoom viewfinder height
   */
  get zoomH() {
    return this.imgH / this.zoomFactor;
  }

  /**
   * zoom viewfinder X position
   */
  get zoomX() {
    return Math.max(
      this.imgX,
      Math.min(
        this.zoomPosX + this.imgX - this.zoomW / 2,
        this.imgX + this.imgW - this.zoomW,
      ),
    );
  }

  /**
   * zoom viewfinder Y position
   */
  get zoomY() {
    return Math.max(
      this.imgY,
      Math.min(
        this.zoomPosY + this.imgY - this.zoomH / 2,
        this.imgY + this.imgH - this.zoomH,
      ),
    );
  }

  /**
   * the ratio between computed image size and natural image size
   */
  get bboxScale() {
    return this.imgH / this.natH;
  }

  /**
   * This positions the actual zoomed image
   */
  get zoomOverlayStyle() {
    if (!this.showZoomLayer) {
      return '';
    }
    return `left: ${this.imgX}px; top: ${this.imgY}px; width: ${
      this.imgW
    }px; height: ${this.imgH}px; background-image: url(${
      this.src
    }); background-size: ${this.zoomFactor *
      100}%; background-position: ${(-this.zoomX + this.imgX) *
      this.zoomFactor}px ${(-this.zoomY + this.imgY) * this.zoomFactor}px;`;
  }

  /**
   * This positions the zoom viewfinder
   */
  get viewFinderStyle() {
    if (!this.showZoomLayer) {
      return '';
    }
    return `width: ${this.zoomW}px; height: ${this.zoomH}px; left: ${this.zoomX}px; top: ${this.zoomY}px;`;
  }

  bboxClickHandler(bbox: DetectionBbox, e?: MouseEvent) {
    if (!this.classifier.isAdmin && this.classifier.canStartTagging) {
      this.classifier.startTagging();
      return;
    }

    // cant edit
    if (!this.classifier.canEdit) {
      // TODO: show why you cant edit
      // and prompt to do something about it
      this.showBboxDialog(bbox);
      return;
    }

    // got a resolved tag
    if (bbox.finalTaggerType) {
      this.showBboxDialog(bbox);
      return;
    }

    // TODO: how to add an expert tag if user + ai already agree?

    // admin and got an expert tag
    if (this.classifier.isAdmin && bbox.expertTag) {
      this.showBboxDialog(bbox);
      return;
    }

    // user and got a user tag
    if (!this.classifier.isAdmin && bbox.userTag) {
      this.showBboxDialog(bbox);
      return;
    }

    this.showContextMenu(bbox, e);
  }

  /**
   * set the active bbox and show the context menu
   */
  showContextMenu(bbox: DetectionBbox, e?: MouseEvent) {
    // show it if its not already open
    if (this.contextMenuActive) {
      return;
    }

    let menuX: number;
    let menuY: number;

    if (e) {
      // the user clicked on the image
      // grab the mouse pos
      menuX = e.clientX;
      menuY = e.clientY;
    } else {
      // triggered by auto-navigate
      // calc the coords
      const { x, y } = (this.$refs
        .img as HTMLElement).getBoundingClientRect() as DOMRect;

      menuX = x + (bbox.bbox.x + bbox.bbox.w) * this.bboxScale;
      menuY = y + bbox.bbox.y * this.bboxScale;
    }

    this.activeBbox = bbox;
    this.contextMenuX = menuX;
    this.contextMenuY = menuY;
    this.$nextTick(() => {
      this.contextMenuActive = true;
    });
  }

  /**
   * show the bbox dialog
   */
  showBboxDialog(bbox: DetectionBbox) {
    if (this.bboxDialogActive) {
      return;
    }
    this.activeBbox = bbox;
    this.$nextTick(() => {
      this.contextMenuActive = false;
      this.bboxDialogActive = true;
    });
  }

  /**
   * create a fauna media tag for the given bbox
   * @param tag
   * @param bbox
   */
  async selectTag(tag: FaunaTag, bbox: DetectionBbox) {
    if (this.classifier.loading) {
      return;
    }

    // add the tag
    try {
      this.classifier.loading = true;
      this.contextMenuActive = false;

      let taggerType = this.classifier.isAdmin
        ? TaggerType.expert
        : TaggerType.user;

      // if theres no user tag, and the tags match, lets make it a user tag regardless of role
      if (!bbox.userTag && bbox.aiTag && bbox.aiTag.faunaTag.id === tag.id) {
        taggerType = TaggerType.user;
      }

      // TODO: provide tagger type
      const fmTag = new FaunaMediaTag({
        faunaTag: tag,
        detectionBbox: bbox,
        taggerType,
      });

      await fmTag.save({
        with: ['faunaTag.id', 'detectionBbox.id'],
      });
    } catch (e) {
      this.classifier.snack.setError({
        text: 'Could not add tag',
        errors: (e as ErrorResponse).response.errors,
      });
      await this.refreshData();
      return;
    } finally {
      this.classifier.loading = false;
    }

    // refresh faunamedia
    await this.refreshData();

    // the bboxes have been refreshed, so re-set the active bbox
    this.activeBbox = this.bboxes.find(b => b.id === bbox.id) || null;

    // handle auto dismiss of bbox if the tag is agreed
    if (
      this.classifier.autoDismissBboxDialog &&
      this.activeBbox &&
      this.activeBbox.tagStatus === 'agreed'
    ) {
      this.activeBbox = null;
      this.classifier.snack.setSuccess('Nice work! We have a match');
      this.$emit('action');
      return;
    }

    // set the trigger action complete && show the bbox dialog
    // this will ensure that when the bbox closes, an action is triggered
    this.triggerActionComplete = true;
    this.bboxDialogActive = true;
  }

  /**
   * set the bbox as blank
   */
  async selectBlank(bbox: DetectionBbox) {
    if (this.classifier.loading) {
      return;
    }

    this.contextMenuActive = false;

    const selection = await confirmDialog({
      title: 'Remove this detection?',
      description:
        'This will remove the detection from the image. Are you sure you want to do this?',
      buttons: [
        {
          key: 'cancel',
          title: 'Cancel',
          color: 'grey',
          text: true,
        },
        {
          key: 'confirm',
          title: 'Remove',
          color: 'red white--text',
          outlined: false,
        },
      ],
    });
    if (selection !== 'confirm') {
      return;
    }

    try {
      this.classifier.loading = true;

      bbox.isBlank = true;
      await bbox.save();
      this.classifier.snack.setSuccess('Detection removed');
    } catch (e) {
      this.classifier.snack.setError({
        text: 'Could not modify bbox',
        errors: (e as ErrorResponse).response.errors,
      });
      await this.refreshData();
      return;
    } finally {
      this.classifier.loading = false;
    }

    await this.refreshData();
    this.activeBbox = null;
    this.$emit('action');
  }

  /**
   * delete a given tag
   */
  async deleteTag(fmTag: FaunaMediaTag) {
    if (this.classifier.loading) {
      return;
    }

    if (!fmTag) {
      console.warn('no tag to delete');
      return;
    }

    try {
      this.classifier.loading = true;
      this.triggerActionComplete = false;
      await fmTag.destroy();
      this.classifier.snack.setSuccess('Tag removed');
    } catch (e) {
      this.classifier.snack.setError({
        text: 'Could not remove tag',
        errors: (e as ErrorResponse).response.errors,
      });
    } finally {
      this.classifier.loading = false;
    }

    await this.refreshData();
    this.$emit('action');
  }

  /**
   * show the zoom layer if its enabled
   */
  mouseEnter() {
    this.showZoomLayer = this.zoomActive;
  }

  /**
   * update the zoom layer position
   */
  mouseMove(e: MouseEvent) {
    this.zoomPosX = e.layerX;
    this.zoomPosY = e.layerY;
  }

  /**
   * hide the zoom layer
   */
  mouseLeave() {
    this.showZoomLayer = false;
  }

  /**
   * toggle the zoom layer on/off
   */
  toggleZoom() {
    if (this.contextMenuActive) {
      return;
    }
    this.zoomActive = !this.zoomActive;
    this.mouseEnter();
  }

  /**
   * handler for image onLoad
   */
  onLoad() {
    this.imageLoaded = true;
    this.adjustSizing();
    this.$nextTick(() => {
      this.$emit('imageLoaded');
    });
  }

  /**
   * get the natural image size and set vars for positioning
   */
  adjustSizing() {
    if (!this.$refs.img) {
      console.warn('no image ref, bailing early');
      return;
    }

    // natural image size
    this.natW = (this.$refs.img as HTMLImageElement).naturalWidth;
    this.natH = (this.$refs.img as HTMLImageElement).naturalHeight;

    // container size
    this.parentW = (this.$el as HTMLElement).offsetWidth;
    this.parentH = (this.$el as HTMLElement).offsetHeight;

    // aspect ratio of the image
    const r = this.natW / this.natH;

    // equivalent to object-fit: contain
    const w = Math.min(this.parentW, this.parentH * r);
    const h = Math.min(this.parentH, this.parentW / r);

    // set the vars used for resizing and positioning the image
    this.imgW = w;
    this.imgH = h;
    this.imgX = (this.parentW - w) / 2;
    this.imgY = (this.parentH - h) / 2;
  }

  /**
   * get the bboxes for the current image
   */
  async refreshData() {
    return this.classifier.refreshFaunaMedia(this.item.id as string);
  }

  created() {
    // create a resize observer to update the image size
    this.observer = new window.ResizeObserver(() => {
      this.adjustSizing();
    });
  }

  async mounted() {
    this.observer.observe(this.$el);
    this.refreshData();
  }

  beforeDestroy() {
    this.observer.disconnect();
  }

  @Watch('bboxes')
  bboxesChanged() {
    if (this.activeBbox) {
      const bboxId = this.activeBbox.id;
      this.activeBbox = this.bboxes.find(b => b.id === bboxId) || null;
    }
  }

  @Watch('bboxDialogActive')
  @Watch('contextMenuActive')
  contextMenuActiveChanged() {
    // unset the active bbox when the context menu and bbox dialog is closed
    this.$nextTick(() => {
      if (!this.contextMenuActive && !this.bboxDialogActive) {
        this.activeBbox = null;

        // tell the classifier that a successful action occured
        if (this.triggerActionComplete) {
          this.triggerActionComplete = false;
          this.$emit('action');
        }
      }
    });
  }
}
