









































import { Component, Prop, Vue } from 'vue-property-decorator';

import StatTile from '@/components/common/StatTile.vue';

import { numberFormat } from '@/util';
import { SurveyProperty } from '@/api';

@Component({
  components: {
    StatTile,
  },
})
export default class PropertyOverview extends Vue {
  @Prop({ required: true }) readonly property: SurveyProperty;

  get numberFormat() {
    return numberFormat;
  }
}
