var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.property)?_c('div',{staticClass:"survey-list-item"},[_c('div',{staticClass:"survey-list-item__nav"},[_c('item-breadcrumb',{attrs:{"property":_vm.property,"site":_vm.site,"survey":_vm.survey}}),_c('div',{staticClass:"survey-list-item__nav-actions"},[_c('v-btn',{attrs:{"depressed":"","color":"secondary primary--text text--darken-1","to":_vm.editSurveyLink}},[_vm._v(" "+_vm._s(_vm.editSurveyText)+" ")]),(_vm.isDraft || _vm.isAdmin)?_c('table-menu-button',{attrs:{"icon":"mdi-dots-horizontal","tooltip":"More Actions","color":"secondary primary--text text--darken-1","menuDense":true}},[(_vm.isDraft)?_c('v-list-item',{attrs:{"to":{
            name: 'property-surveys-edit',
            params: { propertyId: _vm.property.id, surveyId: _vm.survey.id },
          }}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":""}},[_vm._v(" mdi-pencil ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Continue editing ")])],1)],1):_vm._e(),(_vm.isDraft || _vm.isAdmin)?_c('v-list-item',{on:{"click":function($event){return _vm.$emit('deleteItem')}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"small":"","color":"red"}},[_vm._v(" mdi-delete ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_c('span',{staticClass:"red--text"},[_vm._v("Delete")])])],1)],1):_vm._e()],1):_vm._e()],1)],1),_c('div',{staticClass:"survey-list-item__content"},[_c('div',{staticClass:"survey-list-item__property-outline"},[_c('property-outline',{attrs:{"property":_vm.property,"site":_vm.site}})],1),_c('div',{staticClass:"survey-list-item__detail flex-grow-1"},[_c('div',{staticClass:"survey-list-item__title d-flex align-center gap-4"},[_c('router-link',{staticClass:"list-item-title-link",attrs:{"to":_vm.editSurveyLink}},[_c('h4',[_vm._v(_vm._s(_vm.surveyTitle))])]),_c('fauna-survey-chip',{attrs:{"survey":_vm.survey}})],1),_c('div',{staticClass:"survey-list-item__stats"},[(_vm.isDraft && !_vm.fmCount)?_c('div',[_c('stat-btn',{attrs:{"icon":"mdi-camera"}},[_vm._v(" You haven't uploaded any photos to this survey. ")]),_c('div',{staticClass:"info-text"},[_c('v-icon',{attrs:{"small":"","color":"primary"}},[_vm._v("mdi-information-outline")]),_c('router-link',{attrs:{"to":{
                name: 'classifier',
                params: { surveyId: _vm.survey.id },
              }}},[_vm._v(" Edit this survey ")]),_vm._v(" to begin uploading your photos. ")],1)],1):(_vm.isDraft && _vm.fmCount)?_c('div',[_c('div',{staticClass:"stat-btn-grid"},[_c('stat-btn',{attrs:{"icon":"mdi-camera"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.uploadedCount))+" photos uploaded ")]),_c('stat-btn',{attrs:{"icon":"mdi-tag-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.totalBboxCount))+" animals found ")]),_c('stat-btn',{attrs:{"icon":"mdi-border-none-variant"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.blankMediaCount))+" blank photos ")])],1),_c('div',{staticClass:"info-text"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-information-outline ")]),_c('router-link',{attrs:{"to":{
                name: 'classifier',
                params: { surveyId: _vm.survey.id },
              }}},[_vm._v("Edit this survey")]),_vm._v(" to continue uploading your photos or to publish your survey for tagging. ")],1)]):(_vm.isPublished && _vm.isProcessing)?_c('div',{staticClass:"stat-btn-grid"},[_c('stat-btn',{on:{"mouseenter":_vm.updateItem}},[_c('div',{staticClass:"d-flex align-center gap-2"},[_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"stickybeak":true,"name":"small"},scopedSlots:_vm._u([{key:"name",fn:function(){return [_c('strong',[_vm._v("Stickybeak")]),_vm._v(" is processing your survey ")]},proxy:true}],null,false,417204310)}),_c('v-spacer'),_c('v-progress-circular',{attrs:{"indeterminate":"","size":"16","width":"1"}})],1)]),_c('div',{staticClass:"info-text"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-information-outline ")]),_vm._v(" The survey creator will be notified by email when this survey is ready. ")],1)],1):(_vm.isPublished)?_c('div',{staticClass:"stat-btn-grid"},[_c('stat-btn',{attrs:{"icon":"mdi-camera"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.uploadedCount))+" photos uploaded ")]),_c('stat-btn',{attrs:{"icon":"mdi-tag-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.totalBboxCount))+" animals found ")]),_c('stat-btn',{attrs:{"icon":"mdi-border-none-variant"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.blankMediaCount))+" blank photos ")]),_c('div',{staticClass:"info-text"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-information-outline ")]),_c('router-link',{attrs:{"to":{
                name: 'classifier',
                params: { surveyId: _vm.survey.id },
              }}},[_vm._v("Start tagging")]),_vm._v(" this survey to begin identifying animals. ")],1)],1):(_vm.isInProgress)?_c('div',{staticClass:"stat-btn-grid"},[_c('stat-btn',{attrs:{"icon":"mdi-camera"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.uploadedCount))+" photos uploaded ")]),_c('stat-btn',{attrs:{"icon":"mdi-tag-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.totalBboxCount - _vm.survey.tagStatusNone))+" of "+_vm._s(_vm.numberFormat(_vm.survey.totalBboxCount))+" Animals identified ")]),_c('stat-btn',{attrs:{"icon":"mdi-border-none-variant"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.blankMediaCount))+" blank photos ")]),(_vm.survey.totalConflictCount)?_c('stat-btn',{attrs:{"icon":"mdi-alert-circle-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.tagStatusResolved))+" of "+_vm._s(_vm.numberFormat(_vm.survey.totalConflictCount))+" Conflicts Resolved ")]):_vm._e(),_c('div',{staticClass:"info-text"},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"primary"}},[_vm._v(" mdi-information-outline ")]),_vm._v(" Use the "),_c('router-link',{attrs:{"to":{
                name: 'classifier',
                params: { surveyId: _vm.survey.id },
              }}},[_vm._v("classifier")]),_vm._v(" to finish tagging your photos. ")],1)],1):(_vm.isComplete)?_c('div',{staticClass:"stat-btn-grid"},[_c('stat-btn',{attrs:{"icon":"mdi-camera"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.uploadedCount))+" photos uploaded ")]),_c('stat-btn',{attrs:{"icon":"mdi-tag-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.totalBboxCount - _vm.survey.tagStatusNone))+" of "+_vm._s(_vm.numberFormat(_vm.survey.totalBboxCount))+" Animals identified ")]),_c('stat-btn',{attrs:{"icon":"mdi-border-none-variant"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.blankMediaCount))+" blank photos ")]),(_vm.survey.totalConflictCount)?_c('stat-btn',{attrs:{"icon":"mdi-alert-circle-outline"}},[_vm._v(" "+_vm._s(_vm.numberFormat(_vm.survey.tagStatusResolved))+" of "+_vm._s(_vm.numberFormat(_vm.survey.totalConflictCount))+" Conflicts Resolved ")]):_vm._e()],1):_vm._e()]),(_vm.faunaMediaThumbnails.length)?_c('image-zip-preview',{staticClass:"survey-list-item__preview",attrs:{"images":_vm.faunaMediaThumbnails}}):_vm._e(),_c('div',{staticClass:"survey-list-item__avatars"},[_c('div',{staticClass:"d-flex align-center gap-6"},[_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"user":_vm.survey.createdBy,"highlight-me":true,"name":"small"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
              var user = ref.user;
return [_vm._v(" Created by "),_c('strong',[_vm._v(_vm._s(user ? user.name : 'Unknown'))]),_vm._v(" "+_vm._s(_vm.survey.formattedCreatedDate)+" ")]}}],null,false,489947560)}),(_vm.survey.assessedBy)?_c('user-avatar',{staticClass:"user-avatar--sm",attrs:{"user":_vm.survey.assessedBy,"highlight-me":true,"name":"small"},scopedSlots:_vm._u([{key:"name",fn:function(ref){
              var user = ref.user;
return [(user)?_c('span',[_vm._v(" Tagging by "),_c('strong',[_vm._v(_vm._s(user.name))])]):_vm._e()]}}],null,false,288842287)}):_vm._e()],1)])],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }