import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import authModule from '@/store/Auth';
import localeModule from '@/store/Locale';

import NotFoundView from '@/views/NotFoundView.vue';

// simple
import SimpleView from '@/views/SimpleView.vue';

import Dashboard from '@/components/dashboard/Dashboard.vue';

// admin
import AdminNav from '@/components/admin/AdminNav.vue';
import AdminDashboard from '@/components/admin/AdminDashboard.vue';
import AdminUsersList from '@/components/admin/AdminUsersList.vue';
import AdminUsersSingle from '@/components/admin/AdminUsersSingle.vue';
import AdminFocalLandscapesList from '@/components/admin/AdminFocalLandscapesList.vue';
import AdminFocalLandscapesSingle from '@/components/admin/AdminFocalLandscapesSingle.vue';
import AdminPropertiesList from '@/components/admin/AdminPropertiesList.vue';
import AdminPropertiesSingle from '@/components/admin/AdminPropertiesSingle.vue';
import AdminSitesList from '@/components/admin/AdminSitesList.vue';
import AdminSitesSingle from '@/components/admin/AdminSitesSingle.vue';
import AdminSurveysList from '@/components/admin/AdminSurveysList.vue';
import AdminSurveysSingle from '@/components/admin/AdminSurveysSingle.vue';
import AdminFaunaTagGroupsList from '@/components/admin/AdminFaunaTagGroupsList.vue';
import AdminFaunaTagGroupsSingle from '@/components/admin/AdminFaunaTagGroupsSingle.vue';
import AdminFaunaTagsList from '@/components/admin/AdminFaunaTagsList.vue';
import AdminFaunaTagsSingle from '@/components/admin/AdminFaunaTagsSingle.vue';
import AdminProgramsList from '@/components/admin/AdminProgramsList.vue';
import AdminProgramsSingle from '@/components/admin/AdminProgramsSingle.vue';

// user edit
import UserBasicInfo from '@/components/admin/users/UserBasicInfo.vue';
import UserPropertiesList from '@/components/admin/users/UserPropertiesList.vue';
import UserPropertiesSingle from '@/components/admin/users/UserPropertiesSingle.vue';

// property list
import PropertyStub from '@/views/PropertyStub.vue';

// property single
import PropertySingleView from '@/views/PropertySingleView.vue';

import PropertyOverview from '@/components/property/PropertyOverview.vue';
import PropertySurveys from '@/components/property/PropertySurveys.vue';
import PropertyTags from '@/components/property/PropertyTags.vue';
import PropertyFavourites from '@/components/property/PropertyFavourites.vue';
import PropertyUsers from '@/components/property/PropertyUsers.vue';
import PropertyBboxes from '@/components/property/PropertyBboxes.vue';

// survey site create
import SiteStepper from '@/components/site/SiteStepper.vue';

// survey create stepper
import SurveySingle from '@/components/survey/SurveySingle.vue';

// classifier
import Classifier from '@/components/classifier/Classifier.vue';

// share
import ShareView from '@/views/ShareView.vue';

// auth
import AuthView from '@/views/AuthView.vue';

import EoiComplete from '@/components/auth/EoiComplete.vue';
import Login from '@/components/auth/Login.vue';
import PasswordResetForm from '@/components/auth/PasswordResetForm.vue';
import PasswordResetConfirmForm from '@/components/auth/PasswordResetConfirmForm.vue';

import AdminDashView from '@/views/AdminDashView.vue';
import AdminDashPropertyList from '@/components/admin-dash/AdminDashPropertyList.vue';
import AdminDashSurveyList from '@/components/admin-dash/AdminDashSurveyList.vue';
import AdminDashBboxList from '@/components/admin-dash/AdminDashBboxList.vue';

// Cache
import cacheModule from '@/store/Cache';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: '',
    component: SimpleView,
    meta: { showAppBar: true, auth: true },
    children: [
      {
        path: '',
        alias: '/dashboard',
        name: 'home',
        component: Dashboard,
        meta: { title: 'Dashboard' },
      },
    ],
  },
  {
    path: '',
    component: AuthView,
    meta: { showAppBar: true },
    children: [
      {
        path: 'login',
        name: 'login',
        component: Login,
        meta: { title: 'Login' },
      },
      {
        path: 'eoi-complete',
        name: 'eoi-complete',
        component: EoiComplete,
        meta: { title: 'Thank you for your interest' },
      },
      {
        path: 'forgot-password',
        name: 'forgot-password',
        component: PasswordResetForm,
        meta: { title: 'Forgot Password' },
      },
      {
        path: 'create-password',
        name: 'create-password',
        component: PasswordResetForm,
        meta: { title: 'Welcome to Wildtracker' },
      },
      {
        path: 'set-password',
        name: 'set-password',
        component: PasswordResetConfirmForm,
        meta: { title: 'Set Password' },
      },
    ],
  },
  {
    path: '/properties',
    meta: { showAppBar: true, auth: true },
    name: 'property-stub',
    component: PropertyStub,
  },
  {
    path: '/properties',
    meta: { showAppBar: true, auth: true },
    component: PropertySingleView,
    children: [
      {
        path: ':propertyId',
        name: 'property-overview',
        component: PropertyOverview,
      },
      {
        path: ':propertyId/sites/create',
        name: 'property-sites-create',
        component: SiteStepper,
      },
      {
        path: ':propertyId/sites/edit/:siteId',
        name: 'property-sites-edit',
        component: SiteStepper,
      },
      {
        path: ':propertyId/surveys',
        name: 'property-surveys',
        component: PropertySurveys,
      },
      {
        path: ':propertyId/surveys/create',
        name: 'property-surveys-create',
        component: SurveySingle,
      },
      {
        path: ':propertyId/surveys/edit/:surveyId',
        name: 'property-surveys-edit',
        component: SurveySingle,
      },
      {
        path: ':propertyId/tags',
        name: 'property-tags',
        component: PropertyTags,
      },
      {
        path: ':propertyId/favourites',
        name: 'property-favourites',
        component: PropertyFavourites,
      },
      {
        path: ':propertyId/users',
        name: 'property-users',
        component: PropertyUsers,
      },
      {
        path: ':propertyId/stickybeak',
        name: 'property-stickybeak',
        component: PropertyBboxes,
      },
      {
        path: ':propertyId/classifier/:faunaSurveyId?',
        name: 'classifier',
        component: Classifier,
      },
    ],
  },
  {
    path: '',
    meta: { auth: true },
    component: SimpleView,
    children: [
      {
        path: 'classifier/:faunaSurveyId',
        name: 'classifier-alt',
        component: Classifier,
      },
    ],
  },
  {
    path: '/share/:uuid',
    meta: { showAppBar: true },
    component: ShareView,
  },
  {
    path: '/admin-dashboard',
    meta: { showAppBar: true, auth: true },
    component: AdminDashView,
    children: [
      {
        path: '',
        name: 'admin-dashboard-properties',
        component: AdminDashPropertyList,
      },
      {
        path: 'surveys',
        name: 'admin-dashboard-surveys',
        component: AdminDashSurveyList,
      },
      {
        path: 'stickybeak',
        name: 'admin-dashboard-bboxes',
        component: AdminDashBboxList,
      },
    ],
  },
  {
    path: '/admin',
    meta: { showAppBar: true, showNavBar: true, auth: true },
    components: { default: SimpleView, nav: AdminNav },
    children: [
      {
        path: '',
        name: 'admin-home',
        component: AdminDashboard,
      },
      {
        path: 'users',
        name: 'admin-users',
        component: AdminUsersList,
      },
      {
        path: 'users/new',
        component: AdminUsersSingle,
        children: [
          {
            path: '',
            name: 'admin-users-new',
            component: UserBasicInfo,
          },
        ],
      },
      {
        path: 'users/:itemId',
        component: AdminUsersSingle,
        children: [
          {
            path: '',
            name: 'admin-users-edit',
            component: UserBasicInfo,
          },
          {
            path: 'properties',
            name: 'admin-user-properties',
            component: UserPropertiesList,
          },
          {
            path: 'properties/new',
            name: 'admin-user-properties-new',
            component: UserPropertiesSingle,
          },
          {
            path: 'properties/:propertyId',
            name: 'admin-user-properties-edit',
            component: UserPropertiesSingle,
          },
        ],
      },
      {
        path: 'focal-landscapes',
        name: 'admin-focal-landscapes',
        component: AdminFocalLandscapesList,
      },
      {
        path: 'focal-landscapes/new',
        name: 'admin-focal-landscapes-new',
        component: AdminFocalLandscapesSingle,
      },
      {
        path: 'focal-landscapes/:itemId',
        name: 'admin-focal-landscapes-edit',
        component: AdminFocalLandscapesSingle,
      },
      {
        path: 'properties',
        name: 'admin-properties',
        component: AdminPropertiesList,
      },
      {
        path: 'properties/new',
        name: 'admin-properties-new',
        component: AdminPropertiesSingle,
      },
      {
        path: 'properties/:itemId',
        name: 'admin-properties-edit',
        component: AdminPropertiesSingle,
      },
      {
        path: 'sites',
        name: 'admin-sites',
        component: AdminSitesList,
      },
      {
        path: 'sites/new',
        name: 'admin-sites-new',
        component: AdminSitesSingle,
      },
      {
        path: 'sites/:itemId',
        name: 'admin-sites-edit',
        component: AdminSitesSingle,
      },
      {
        path: 'surveys',
        name: 'admin-surveys',
        component: AdminSurveysList,
      },
      {
        path: 'surveys/new',
        name: 'admin-surveys-new',
        component: AdminSurveysSingle,
      },
      {
        path: 'surveys/:itemId',
        name: 'admin-surveys-edit',
        component: AdminSurveysSingle,
      },
      {
        path: 'fauna-tag-groups/',
        name: 'admin-fauna-tag-groups',
        component: AdminFaunaTagGroupsList,
      },
      {
        path: 'fauna-tag-groups/new',
        name: 'admin-fauna-tag-groups-new',
        component: AdminFaunaTagGroupsSingle,
      },
      {
        path: 'fauna-tag-groups/:itemId',
        name: 'admin-fauna-tag-groups-edit',
        component: AdminFaunaTagGroupsSingle,
      },
      {
        path: 'fauna-tags/',
        name: 'admin-fauna-tags',
        component: AdminFaunaTagsList,
      },
      {
        path: 'fauna-tags/new',
        name: 'admin-fauna-tags-new',
        component: AdminFaunaTagsSingle,
      },
      {
        path: 'fauna-tags/:itemId',
        name: 'admin-fauna-tags-edit',
        component: AdminFaunaTagsSingle,
      },
      {
        path: 'programs',
        name: 'admin-programs',
        component: AdminProgramsList,
      },
      {
        path: 'programs/new',
        name: 'admin-programs-new',
        component: AdminProgramsSingle,
      },
      {
        path: 'programs/:itemId',
        name: 'admin-programs-edit',
        component: AdminProgramsSingle,
      },
    ],
  },
  {
    path: '*',
    meta: {},
    component: NotFoundView,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
});

router.beforeEach(async (to, from, next) => {
  // init once
  if (!authModule.ready) {
    await authModule.init();
  }
  if (!localeModule.ready) {
    await localeModule.init();
  }

  cacheModule.init();

  const roles = to.matched.reduce(
    (combined, route) => [...combined, ...((route.meta.roles as []) || [])],
    [],
  );

  // Allow or deny access to current route
  const canAccess = authModule.canAccess(roles);
  authModule.setCanAccessRoute(canAccess);

  // if auth required, redirect to login
  const requiresAuth = to.matched.some(match => match.meta.auth);
  if (requiresAuth && !authModule.isLoggedIn) {
    next(`/login?next=${to.path}`);
    return;
  }

  next();
});

export default router;
